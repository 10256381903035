<template>
  <v-container fluid>
    <PageHeaderSlot> </PageHeaderSlot>

    <Datatable
      :isLoading="tableLoading"
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page="tablePage"
      :pageLimit="tableLimit"
      :itemTotal="tableItemTotal"
      enableSearch
      @search-clicked="searchKeyword($event)"
      @search-input="tableSearchKey = $event"
      enableAdvancedSearch
      @advanced-search-clicked="openAdvancedSearchDialog($event)"
      enableClearAll
      @all-search-cleared="clearSearch()"
      @refresh-clicked="refreshData()"
      enableRefresh
      @options-update="onTableOptionsChange($event)"
      actionViewRouteLink="PayTuitionPayment"
      enableMultiTypeSearch
      :searchTypeOptions="searchTypeOptions"
      :searchType.sync="tableSearchType"
      :initSearchKeyword="querySearch"
    >
      <template v-slot:[`item.student_name`]="{ item }">
        <div class="d-flex align-center">
          <div style="white-space: nowrap;">{{ item.student_name }}</div>
          <v-btn icon text depressed x-small :to="{ name: 'StudentDetails', params: { id: item.id } }">
            <v-icon class="fi fi-rr-eye mr-1" size="12" color="primary"></v-icon>
          </v-btn>
        </div>
      </template>
    
      <template v-slot:[`item.order_status`]="{ item }">
        <v-chip
          :class="{
            'chip-active': item.order_status === 'expired',
            'chip-error': item.order_status === 'cancelled',
            'chip-inactive':
              item.order_status === 'pending' || item.order_status === 'null' || item.order_status === 'processing',
          }"
        >{{ item.order_status | formatOrderStatus }}</v-chip>
      </template>

      <template v-slot:[`item.next_order_status`]="{ item }">
        <v-chip
          :class="{
            'chip-active': item.next_order_status === 'expired',
            'chip-error': item.next_order_status === 'cancelled',
            'chip-inactive':
              item.next_order_status === 'pending' ||
              item.next_order_status === 'null' ||
              item.next_order_status === 'processing',
          }"
        >{{ item.next_order_status | formatOrderStatus }}</v-chip>
      </template>

      <template v-slot:[`item.actionApplyCourse`]="{ item }">
        <v-btn class="primary" depressed text :to="{ name: 'PayTuitionPayment', params: { id: item.id } }">
          <v-icon class="fi fi-rr-add mr-3" small></v-icon>
          {{ $t('applyCourse') }}
        </v-btn>
      </template>
    </Datatable>

    <DialogLayout
      :openDialog="advancedDialogOpen"
      :maxWidth="910"
      title="table.clearSearch"
      enableScroll
      confirmBtnText="table.search"
      @close="handleAdvancedSearchDialogClose($event)"
    >
      <v-row class="ma-0">
        <v-col cols="12" sm="6">
          <FormSelect
            label="grade"
            :fieldValue.sync="filter.grade"
            :options="$gradeOptions"
            placeholder="selectGrade"
            hideDetails
            dense
          />
        </v-col>
        <v-col cols="12" sm="6">
          <FormInput label="studentSchool" :fieldValue.sync="filter.school" placeholder="studentSchool" hideDetails dense />
        </v-col>
        <v-col cols="12" sm="6">
          <FormSelect
            label="memberLevel"
            :fieldValue.sync="filter.memberLevel"
            :options="$memberOptions"
            placeholder="selectMemberLevel"
            hideDetails
            dense
          />
        </v-col>
        <v-col cols="12" sm="6">
          <FormDatePicker
            label="regYearMonth"
            placeholder="selectRegYearMonth"
            hideDetails
            :dateValue.sync="filter.regYearMonth"
            isYearMonthOnly
            dense
            disabledActionButtons
          />
        </v-col>
      </v-row>
    </DialogLayout>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'
import DialogLayout from '@/components/layout/DialogLayout.vue'
import FormSelect from '@/components/formField/FormSelect.vue'
import FormDatePicker from '@/components/formField/FormDatePicker.vue'
import FormInput from '@/components/formField/FormInput.vue'

export default {
  name: 'PayTuitionList',
  components: {
    Datatable,
    FormSelect,
    FormDatePicker,
    DialogLayout,
    FormInput,
  },
  computed: {
    query() {
      return this.$route.query
    },
    querySearch() {
      return this.$route.query.search || ''
    },
  },
  data: (vm) => ({
    tableLoading: false,
    tableData: [],
    tablePage: 1,
    tableLimit: 10,
    tableItemTotal: 0,
    tableSearchKey: '',
    tableSearchType: 'name',
    advancedDialogOpen: false,

    /* 進階搜尋 */
    filter: {
      grade: '',
      school: '',
      memberLevel: '',
      regYearMonth: '',
    },
    filterSelected: {
      // 禁咗搜尋之後save
      grade: '',
      school: '',
      memberLevel: '',
      regYearMonth: '',
    },

    // --- static data
    tableHeaders: [
      { value: 'student_code', text: vm.$t('studentCode') },
      { value: 'grade', text: vm.$t('grade') },
      { value: 'student_name', text: vm.$t('name') },
      { value: 'school', text: vm.$t('studentSchool') },
      { value: 'phone', text: vm.$t('phone') },
      { value: 'reg_date', text: vm.$t('regDate') },
      { value: 'order_status', text: vm.$t('currentMonthOrderStatus'), align: 'center' },
      { value: 'next_order_status', text: vm.$t('nextMonthOrderStatus'), align: 'center' },
      { value: 'actionApplyCourse', text: '', align: 'end' },
    ],
    searchTypeOptions: [
      { value: 'name', text: vm.$t('studentName') },
      { value: 'student_code', text: vm.$t('studentCode') },
      { value: 'phone', text: vm.$t('phone') },
    ],
  }),
  methods: {
    async getPayTuitionData(regenUrl = true) {
      if (regenUrl === true) {
        this.regenerateURL()
      }

      this.tableLoading = true
      try {
        let payload = {
          filter_limit: this.tableLimit,
          filter_page: this.tablePage - 1,
        }

        const selectedSchool = await this.getUserSelectedSchool()
        if (this.$validate.DataValid(selectedSchool)) {
          payload.center_id = selectedSchool
        }

        const filterItems = []
        if (this.$validate.DataValid(this.tableSearchKey)) {
          const keyword = this.tableSearchKey.trim()

          if (this.$validate.DataValid(this.tableSearchType)) {
            if (this.tableSearchType === 'id') {
              if (this.$validate.isValidId(keyword)) {
                const id = keyword.substring(1)
                filterItems.push({ key: 'id', value: parseInt(id) })
              } else if (this.$validate.regexNumber(keyword)) {
                filterItems.push({ key: 'id', value: parseInt(keyword) })
              }
            } else {
              filterItems.push({ key: this.tableSearchType, value: keyword })
            }
          } else {
            payload.search = keyword
          }
        }

        if (this.$validate.DataValid(this.filterSelected.grade)) {
          filterItems.push({ key: 'grade', value: this.filterSelected.grade })
        }

        if (this.$validate.DataValid(this.filterSelected.school)) {
          filterItems.push({ key: 'school', value: this.filterSelected.school })
        }

        if (this.$validate.DataValid(this.filterSelected.memberLevel)) {
          filterItems.push({ key: 'level', value: this.filterSelected.memberLevel })
        }

        if (this.$validate.DataValid(this.filterSelected.regYearMonth)) {
          filterItems.push({ key: 'create_date', value: this.filterSelected.regYearMonth })
        }
        if (filterItems.length) {
          payload.filter_item = filterItems
        }

        const { data, total } = await this.$Fetcher.GetPayTuitions(payload)
        this.tableItemTotal = total

        this.tableData = data.map(el => {
          return {
            id: el.student_id,
            student_id: el.student_id,
            student_code: el.student_code,
            grade: el.grade,
            student_name: el.student_name,
            phone: el.phone,
            reg_date: el.reg_date,
            order_status: el.order_status,
            next_order_status: el.next_order_status,
            school: el.student_school_name,
          }
        })
        await this.getNextOrderStatus()
        this.tableLoading = false
      } catch (err) {
        this.$common.error(err)
        this.tableData = []
        this.tableLoading = false
      }
    },
    async getNextOrderStatus(count = 0) {
      if (count < this.tableData.length) {
        try {
          const data = await this.$Fetcher.GenNextMonthPreviewOrder(this.tableData[count].id)

          const today = new Date();
          for (let i = 0; i < data.item_datas.length; i++) {
            const d = new Date(data.item_datas[i].date);

            if (d.getFullYear() === today.getFullYear() && d.getMonth() === today.getMonth()) {
              this.tableData[count].order_status = 'pending';
              break;
            }
          }
          
          const nextMonth = new Date();
          nextMonth.setMonth(today.getMonth() + 1);
          for (let i = 0; i < data.item_datas.length; i++) {
            const d = new Date(data.item_datas[i].date);

            if (d.getFullYear() === nextMonth.getFullYear() && d.getMonth() === nextMonth.getMonth()) {
              this.tableData[count].next_order_status = 'pending';
              break;
            }
          }
        } catch {
        } finally {
          count += 1;
          await this.getNextOrderStatus(count);
        }
      }
    },
    searchKeyword(keyword) {
      this.tablePage = 1
      this.tableItemTotal = 0
      this.tableSearchKey = keyword.trim()
      this.getPayTuitionData()
    },
    clearSearch() {
      this.tablePage = 1
      this.tableItemTotal = 0
      this.tableSearchKey = ''
      this.tableSearchType = this.searchTypeOptions[0].value
      this.filterSelected.grade = ''
      this.filterSelected.school = ''
      this.filterSelected.memberLevel = ''
      this.filterSelected.regYearMonth = ''
      this.getPayTuitionData()
    },
    refreshData() {
      this.tableLimit = 10
      this.tablePage = 1
      this.tableItemTotal = 0
      this.getPayTuitionData()
    },
    onTableOptionsChange(options) {
      if (options.itemsPerPage !== this.tableLimit) {
        this.tablePage = 1
      } else {
        this.tablePage = options.page
      }

      this.tableLimit = options.itemsPerPage
      this.getPayTuitionData()
    },
    openAdvancedSearchDialog(allow) {
      if (this.tableLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })

        return
      }

      if (allow === true) {
        this.filter.grade = this.filterSelected.grade
        this.filter.school = this.filterSelected.school
        this.filter.memberLevel = this.filterSelected.memberLevel
        this.filter.regYearMonth = this.filterSelected.regYearMonth
        this.advancedDialogOpen = true
      }
    },
    handleAdvancedSearchDialogClose(action) {
      if (action === true) {
        this.filterSelected.grade = this.filter.grade
        this.filterSelected.school = this.filter.school
        this.filterSelected.memberLevel = this.filter.memberLevel
        this.filterSelected.regYearMonth = this.filter.regYearMonth
        this.tablePage = 1
        this.tableItemTotal = 0
        this.getPayTuitionData()
      }
      this.advancedDialogOpen = false
    },

    getQuery() {
      if (Object.keys(this.query).length > 0) {
        if (this.$validate.DataValid(this.query.searchType)) {
          this.tableSearchType = this.query.searchType
        }

        if (this.$validate.DataValid(this.querySearch)) {
          this.tableSearchKey = this.querySearch
        }

        if (this.$validate.DataValid(this.query.page) && this.$validate.regexNumber(this.query.page)) {
          this.tablePage = parseInt(this.query.page)
        }

        if (this.$validate.DataValid(this.query.limit) && this.$validate.regexNumber(this.query.limit)) {
          this.tableLimit = parseInt(this.query.limit)
        }

        if (this.$validate.DataValid(this.query.grade)) {
          this.filterSelected.grade = this.query.grade
        }
        if (this.$validate.DataValid(this.query.school)) {
          this.filterSelected.school = this.query.school
        }
        if (this.$validate.DataValid(this.query.level)) {
          this.filterSelected.memberLevel = this.query.level
        }
        if (this.$validate.DataValid(this.query.date)) {
          this.filterSelected.regYearMonth = this.query.date
        }
      }
    },

    regenerateURL() {
      const q = {
        searchType: this.tableSearchType,
        search: this.tableSearchKey.trim(),
        page: this.tableLimit !== 10 || this.tablePage !== 1 ? this.tablePage : null,
        limit: this.tableLimit !== 10 || this.tablePage !== 1 ? this.tableLimit : null,
        grade: this.filterSelected.grade,
        school: this.filterSelected.school,
        level: this.filterSelected.memberLevel,
        date: this.filterSelected.regYearMonth,
      }

      const newQuery = this.$common.diffQuery(q, this.query)
      if (newQuery) {
        this.$router.replace({ query: newQuery })
      }
    },

    async handleSiteLoaded() {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: PayTuitionList')
      this.getQuery()
      this.tableLoading = false
      await this.getPayTuitionData(false)
    },
  },
  destroyed() {
    window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
  mounted() {
    this.tableLoading = true
    window.addEventListener('onSiteLoaded', this.handleSiteLoaded)
  },
}
</script>
